import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function ReporttemplateEdit() {
  let layoutdata = JSON.parse(localStorage.getItem("layout"));

  const [selectedIds, setSelectedIds] = useState(
    layoutdata && layoutdata.layout ? layoutdata.layout : []
  );
  const url_params = useParams();
  const navigate = useNavigate();

  const tableHeadings = [
    { id: 1, name: "Admission No" },
    { id: 2, name: "Students" },
    { id: 3, name: "Reading 1 May 23" },
    { id: 4, name: "Reading 2 Oct 23" },
    { id: 5, name: "Reading 3 Feb 24" },
    { id: 6, name: "Spatial Stanine" },
    { id: 7, name: "Asset Science" },
    { id: 8, name: "End of the Year Report Grade March 23" },
    { id: 9, name: "Target for Final Exam" },
    { id: 10, name: "Improvement Steps" },
    { id: 11, name: "Half Yearly Grade" },
    { id: 12, name: "Final Grade March 24" },
    { id: 13, name: "On Target (Y/N)" },
  ];

  const handleCellSelect = (e, id) => {
    if (e.target.checked) {
      setSelectedIds((prev) => [...prev, id]);
    } else {
      setSelectedIds((prev) => prev.filter((i) => i !== id));
    }
  };
  //   console.log(selectedIds);

  const handleSubmit = () => {
    const layoutdetails = {
      class: url_params.class_id,
      subject: url_params.subject,
      academic_year: url_params.academic_year,
      layout: selectedIds,
    };

    // console.log(layoutdetails);
    localStorage.setItem("layout", JSON.stringify(layoutdetails));

    navigate(-1);
  };

  return (
    <Box padding={{ xs: 0, md: 2 }} ml={{ xs: 0, md: 1 }} mt={{ xs: 1 }}>
      <Grid container spacing={2}>
        {tableHeadings.map((data, index) => {
          return (
            <Grid item xs={3} key={index}>
              <Card sx={{ minWidth: 275, minHeight: 120 }}>
                <CardContent>
                  <Checkbox
                    checked={selectedIds.includes(data.id)}
                    onChange={(e) => handleCellSelect(e, data.id)}
                  />
                  <Typography sx={{ fontSize: 14, ml: "12px" }}>
                    {data.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <Tooltip title="Click to save changes">
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
}

export default ReporttemplateEdit;
