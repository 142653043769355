import React from "react";
import Grade1 from "./Grade1";
import AllGrade from "./AllGrade";
import Grade2 from "./Grade2";
import Grade3to10 from "./Grade3to10";

const Index = ({ classId, subjectName, subject, reports, year }) => {
  let grade3to10 = [
    "class1234567",
    "class12345678",
    "class123456789",
    "class1234567890",
    "class1723",
    "class12384",
    "class129345",
    "class12310456",
  ];
  return (
    <div>
      {classId === "class12345" && year == "2024-2025" ? (
        <Grade1
          subjectName={subjectName}
          subject={subject}
          reports={reports}
          year={year}
        />
      ) : classId === "class123456" && year == "2024-2025" ? (
        <Grade2
          subjectName={subjectName}
          subject={subject}
          reports={reports}
          year={year}
        />
      ) : grade3to10.includes(classId) && year == "2024-2025" ? (
        <Grade3to10
          subjectName={subjectName}
          subject={subject}
          reports={reports}
          year={year}
        />
      ) : (
      <AllGrade
        classId={classId}
        subjectName={subjectName}
        subject={subject}
        reports={reports}
        year={year}
      />
       )}
    </div>
  );
};

export default Index;
